function makeProductBoxSameHeight() {
	var highestBox = 0;
	var elementArr = [".title-name", ".price"];

	$.each( elementArr, function( key, value ) {
		highestBox = 0;
		$('.c-product ' + value).height('auto');

		$('.c-product ' + value).each(function() {
			if ($(this).height() > highestBox) {
				highestBox = $(this).height();
			}
		});

		if ($(window).width() > 320) {	// Chỉ căn bằng nhau trên desktop
			$('.c-product ' + value).height(highestBox);
		}
	});
}
function makeProductRunBoxSameHeight() {
	var highestBox = 0;
	var elementArr = [".title-name", ".price"];

	$.each( elementArr, function( key, value ) {
		highestBox = 0;
		$('.c-product-run ' + value).height('auto');

		$('.c-product-run ' + value).each(function() {
			if ($(this).height() > highestBox) {
				highestBox = $(this).height();
			}
		});

		if ($(window).width() > 320) {	// Chỉ căn bằng nhau trên desktop
			$('.c-product-run ' + value).height(highestBox);
		}
	});
}
function makeProductCateRunBoxSameHeight() {
	var highestBox = 0;
	var elementArr = [".title-name", ".price"];

	$.each( elementArr, function( key, value ) {
		highestBox = 0;
		$('.c-product-run.c-product-home ' + value).height('auto');

		$('.c-product-run.c-product-home ' + value).each(function() {
			if ($(this).height() > highestBox) {
				highestBox = $(this).height();
			}
		});

		if ($(window).width() > 320) {	// Chỉ căn bằng nhau trên desktop
			$('.c-product-run.c-product-home ' + value).height(highestBox);
		}
	});
}
$(document).ready(function($) {
	makeProductBoxSameHeight();

	$(window).on('load resize', function(){
		makeProductBoxSameHeight();
		makeProductRunBoxSameHeight();
	});
});