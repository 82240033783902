$(document).ready(function() {
	$('.submenu-caret-wrapper').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();
		$(this).closest('li').toggleClass('open');
	});
	$('.navbar-toggle').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();
		$('#bs-example-navbar-collapse-1').toggleClass('in');
	});
});